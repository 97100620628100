/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgreementAsDocResponseUI } from '../models/AgreementAsDocResponseUI';
import type { AgreementResponseUI } from '../models/AgreementResponseUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AgreementControllerService {

    /**
     * Get a customer's agreement
     * User must have PUI CREATE_ORDER to access this resource
     * @param scopeId
     * @param tscid
     * @returns AgreementResponseUI Successful request. Response contains agreement.
     * @throws ApiError
     */
    public static getAgreement(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<AgreementResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/agreement',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            errors: {
                403: `Unauthorized request.`,
                404: `Customer has no agreement.`,
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Get a customer's agreement as a PDF document
     * User must have PUI CREATE_ORDER to access this resource
     * @param scopeId
     * @param tscid
     * @param contractNumber
     * @returns AgreementAsDocResponseUI Successful request. Response contains agreement.
     * @throws ApiError
     */
    public static getAgreementAsDoc(
        scopeId: string,
        tscid: string,
        contractNumber: string,
    ): CancelablePromise<AgreementAsDocResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/agreement-as-doc/{contractNumber}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'contractNumber': contractNumber,
            },
            errors: {
                403: `Unauthorized request.`,
                404: `Customer has no agreement.`,
                500: `Internal server error.`,
            },
        });
    }

    /**
     * Get a customer's agreement as a PDF document
     * User must have PUI CREATE_ORDER to access this resource
     * @param scopeId
     * @param tscid
     * @returns AgreementAsDocResponseUI Successful request. Response contains agreement.
     * @throws ApiError
     */
    public static getAgreementAsDoc1(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<AgreementAsDocResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/agreement-as-doc',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            errors: {
                403: `Unauthorized request.`,
                404: `Customer has no agreement.`,
                500: `Internal server error.`,
            },
        });
    }

}