import { useEffect, useState } from "react";
import type { MessageObject } from "@messageformat/react";
import { fetchMicrocopy } from "@telia/b2x-microcopy";
import { currentLanguage, currentLocale } from "@telia/b2b-i18n";
import { adjustContentfulKeys } from "./contentful-key-mapper";

interface TranslationHook {
  messages: MessageObject | undefined;
}

export function useTranslations(contentfulIdentifiers: string[]): TranslationHook {
  const [messages, setMessages] = useState<MessageObject>();
  useEffect(() => {
    (async (): Promise<void> => {
      const [contentfulMessages, localTranslations] = await Promise.all([
        fetchMicrocopy(currentLocale(), contentfulIdentifiers),
        import(`./locale.${currentLanguage()}.yaml`),
      ]);
      const adjustedContentfulData: Record<string, string> =
        adjustContentfulKeys(contentfulMessages);
      setMessages({ ...adjustedContentfulData, ...localTranslations.default });
    })();
  }, []);

  return {
    messages,
  };
}
