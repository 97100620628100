import * as React from "react";
import { Message, useMessage } from "@messageformat/react";
import { useEffect, useState } from "react";
import { getParamsFromUrl } from "../../utils/urlUtils";
import { Grid, Link, Paragraph, Skeleton } from "@purpurds/purpur";
import "@telia/b2b-basket-wrapper";
import "@telia/b2b-broadband-product-listing";
import { B2bLayout } from "@telia/b2b-layout/react-cjs";
import { B2xResultMessage } from "@telia/b2x-result-message/react-cjs";
import "@telia/b2x-inline-dropdown";
import { purpurColorTextToneOnTonePrimary } from "@purpurds/tokens/color/variables";
import { getAgreementAsDoc } from "../../services/AgreementControllerService";
import { addNewToast, getAllMessages } from "@telia/b2b-message-service";
import type { MessageType } from "@telia/b2b-message-service/@types/types";

export const MainView = () => {
  const { scopeId, tscid } = getParamsFromUrl();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<string | undefined>(undefined);
  const [agreementTitle, setAgreementTitle] = useState<string | undefined>(undefined);
  const [agreementPending, setAgreementPending] = useState<boolean>(true);
  const [criticalError, setCriticalError] = useState<boolean>(false);

  const pageHeading = useMessage("header.title");
  const backLinkLabel = useMessage("header.backLink");
  const pageTitle = useMessage("page.title");
  const criticalErrorTitle = useMessage("criticalError.title");
  const criticalErrorContent = useMessage("criticalError.content");
  const criticalErrorActionText = useMessage("criticalError.actionText");
  const errorTitle = useMessage("error.title");
  const errorAgreementContent = useMessage("error.agreementContent");
  const errorBasketContent = useMessage("error.basketContent");

  useEffect(() => {
    setOpenDrawer(new URLSearchParams(window.location.search).has("show-basket"));

    (async (): Promise<void> => {
      if (scopeId && tscid) {
        try {
          const ag = await getAgreementAsDoc(scopeId, tscid);
          setAgreement(ag.agreementDoc);
          setAgreementTitle(ag.documentTitle);
          setAgreementPending(false);
        } catch (error) {
          if (error && typeof error === "object" && "status" in error && error.status === 400) {
            return;
          }
          addNewToast("get-agreement-error", "warning", errorTitle, errorAgreementContent);
        } finally {
          setAgreementPending(false);
        }
      }
    })();

    if (scopeId && tscid) {
      const basket = document.getElementById("basket-wrapper");
      basket?.addEventListener("update-drawer-state", (event) => {
        const isOpen = (event as CustomEvent).detail;

        setOpenDrawer(isOpen);
      });

      basket?.addEventListener("get-basket-error", (event) => {
        const [hasError, source] = (event as CustomEvent).detail;

        if (hasError && !getAllMessages().some((x: MessageType) => x.id === "get-basket-error")) {
          if (source !== "spock") {
            addNewToast("get-basket-error", "warning", errorTitle, errorBasketContent);
          } else {
            setCriticalError(true);
          }
        }
      });
    }
  }, []);

  return (
    <div>
      {scopeId && tscid && (
        <b2b-basket-wrapper
          id="basket-wrapper"
          data-testid="basket-wrapper"
          is-open={openDrawer}
          tscid={tscid}
        />
      )}

      <B2bLayout
        data-testid="layout"
        heading={pageHeading}
        page-title={pageTitle}
        back-link-label={backLinkLabel}
        back-link-href={`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`}
        selected-organisation={tscid}
        organisation-selector-disabled
        show-organisation-selector
      >
        {(agreementPending || agreement) && (
          /* @ts-expect-error slot attribute is for B2bLayout to place the grid correctly */
          <Grid slot="header">
            <Grid>
              <Grid.Item colSpanSm={4}>
                {!agreementPending && (
                  <Paragraph style={{ color: purpurColorTextToneOnTonePrimary }}>
                    <Message id="header.description" />
                    <Link
                      href={`data:application/pdf;base64,${agreement}`}
                      variant="text"
                      negative
                      //@ts-expect-error download attribute is for browser to download pdf directly on link activation.
                      download={agreementTitle}
                    >
                      <Message id="header.link" />
                    </Link>
                  </Paragraph>
                )}
                {agreementPending && (
                  <Skeleton negative style={{ height: "2.4rem", width: "35rem" }} />
                )}
              </Grid.Item>
            </Grid>
          </Grid>
        )}
        <Grid>
          <Grid>
            <Grid.Item colSpanSm={4}>
              {criticalError ? (
                <B2xResultMessage
                  data-testid="critical-error"
                  graphic="technical-error"
                  heading={criticalErrorTitle}
                  headingTag="h2"
                  completeDescription={criticalErrorContent}
                  actionType="button"
                  actionLabel={criticalErrorActionText}
                  actionLeftIcon="sync"
                  onButtonClick={() => window.location.reload()}
                />
              ) : (
                <b2b-broadband-product-listing
                  data-testid="broadband-product-listing"
                  scope-id={scopeId}
                  tscid={tscid}
                />
              )}
            </Grid.Item>
          </Grid>
        </Grid>
      </B2bLayout>
    </div>
  );
};
