/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditCheckUI } from '../models/CreditCheckUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationInformationControllerService {

    /**
     * Get status if organization is blacklisted for fraud
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns boolean Successful request
     * @throws ApiError
     */
    public static isBlackListed(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/isblacklisted',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * Get response from credit check
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns CreditCheckUI Successful request
     * @throws ApiError
     */
    public static getCreditCheck(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<CreditCheckUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/creditcheck',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}