import { match } from "path-to-regexp";

interface Match {
  params: Params;
}
interface Params {
  tscid: string;
  scopeId: string;
}

export function getParamsFromUrl(url = window.location.pathname): Params {
  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/bredband")(url) as Match;

  return params;
}
