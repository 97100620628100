import React from "react";
import { MessageProvider } from "@messageformat/react";
import { MainView } from "../MainView";
import { currentLanguage } from "@telia/b2b-i18n";
import { useTranslations } from "../../locales/useTranslation";

export default function Scaffolding() {
  const { messages } = useTranslations([]);

  return (
    <>
      {messages && (
        <MessageProvider locale={currentLanguage()} messages={messages}>
          <MainView />
        </MessageProvider>
      )}
    </>
  );
}
