/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutRequestUI } from '../models/CheckoutRequestUI';
import type { CheckoutResponseUI } from '../models/CheckoutResponseUI';
import type { OrdersUI } from '../models/OrdersUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderControllerService {

    /**
     * checkout basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @param parentBasketId
     * @returns CheckoutResponseUI Successful request
     * @throws ApiError
     */
    public static checkout(
        scopeId: string,
        tscid: string,
        requestBody: CheckoutRequestUI,
        parentBasketId: string = '',
    ): CancelablePromise<CheckoutResponseUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/checkout',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'parentBasketId': parentBasketId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get orders
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param basketId
     * @param lang
     * @returns OrdersUI Successful request
     * @throws ApiError
     */
    public static getOrders(
        scopeId: string,
        tscid: string,
        basketId: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<OrdersUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/orders/{basketId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'basketId': basketId,
            },
            query: {
                'lang': lang,
            },
        });
    }

}