/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessoryCategoryUI } from '../models/AccessoryCategoryUI';
import type { GetAccessoriesAccessoryProductUI } from '../models/GetAccessoriesAccessoryProductUI';
import type { GetSubscriptionProductsRequestUI } from '../models/GetSubscriptionProductsRequestUI';
import type { HardwareProductBundleUI } from '../models/HardwareProductBundleUI';
import type { MobileDevicePricesUI } from '../models/MobileDevicePricesUI';
import type { PageUIGetAccessoriesAccessoryProductUI } from '../models/PageUIGetAccessoriesAccessoryProductUI';
import type { ProcessProductListUI } from '../models/ProcessProductListUI';
import type { SubscriptionProductBundleUI } from '../models/SubscriptionProductBundleUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductControllerService {

    /**
     * Get subscription products with all applicable addons
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @param lang
     * @returns SubscriptionProductBundleUI Successful request
     * @throws ApiError
     */
    public static getExtendedSubscriptionProducts(
        scopeId: string,
        tscid: string,
        requestBody: GetSubscriptionProductsRequestUI,
        lang: string = 'SV',
    ): CancelablePromise<Array<SubscriptionProductBundleUI>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/subscription-products-with-addons',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get accessories
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param mobileDeviceBundleCode
     * @param lang
     * @returns GetAccessoriesAccessoryProductUI Successful request
     * @throws ApiError
     */
    public static getRecommendedAccessoriesProducts(
        scopeId: string,
        tscid: string,
        mobileDeviceBundleCode: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<Array<GetAccessoriesAccessoryProductUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/{mobileDeviceBundleCode}/recommendations',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'mobileDeviceBundleCode': mobileDeviceBundleCode,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Get products
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns SubscriptionProductBundleUI Successful request
     * @throws ApiError
     */
    public static getSubscriptionProducts(
        scopeId: string,
        tscid: string,
        lang: string = 'SV',
    ): CancelablePromise<Array<SubscriptionProductBundleUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/subscription-products',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Get process products (port-in/transfer)
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns ProcessProductListUI Successful request
     * @throws ApiError
     */
    public static getProcessProducts(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<ProcessProductListUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/process-products',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * Get prices for mobile device
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param tscid
     * @param offer
     * @param product
     * @param periods
     * @returns MobileDevicePricesUI Successful request
     * @throws ApiError
     */
    public static getPricesForMobileDevice(
        scopeId: string,
        tscid: string,
        offer: string,
        product: string,
        periods?: Array<string>,
    ): CancelablePromise<MobileDevicePricesUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/prices/offer/{offer}/product/{product}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'offer': offer,
                'product': product,
            },
            query: {
                'periods': periods,
            },
        });
    }

    /**
     * Get mobile devices
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns HardwareProductBundleUI Successful request
     * @throws ApiError
     */
    public static getHardwareProducts(
        scopeId: string,
        tscid: string,
        lang: string = 'SV',
    ): CancelablePromise<Array<HardwareProductBundleUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/hardware-products',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Get EMN products
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns SubscriptionProductBundleUI Successful request
     * @throws ApiError
     */
    public static getEmnSubscriptionProducts(
        scopeId: string,
        tscid: string,
        lang: string = 'SV',
    ): CancelablePromise<Array<SubscriptionProductBundleUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/emn-products',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Get accessory categories
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns AccessoryCategoryUI Successful request
     * @throws ApiError
     */
    public static getAccessoryCategories(
        scopeId: string,
        tscid: string,
        lang: string = 'SV',
    ): CancelablePromise<Array<AccessoryCategoryUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/accessory-categories',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Get accessories
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @param page
     * @param pageSize
     * @param compatibleWith
     * @param accessoryCategoryFilter
     * @param sortBy
     * @returns PageUIGetAccessoriesAccessoryProductUI Successful request
     * @throws ApiError
     */
    public static getAccessoriesProducts(
        scopeId: string,
        tscid: string,
        lang: string = 'SV',
        page: number = 1,
        pageSize: number = 15,
        compatibleWith?: Array<string>,
        accessoryCategoryFilter?: string,
        sortBy: string = 'NEWEST',
    ): CancelablePromise<PageUIGetAccessoriesAccessoryProductUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/accessories-products',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
                'page': page,
                'pageSize': pageSize,
                'compatibleWith': compatibleWith,
                'accessoryCategoryFilter': accessoryCategoryFilter,
                'sortBy': sortBy,
            },
        });
    }

}