export function adjustContentfulKeys(
  contentfulData: Record<string, string>
): Record<string, string> {
  return Object.entries(contentfulData).reduce(function mapper(
    adjustedContentfulData: Record<string, string>,
    currentKeyValuePair: [string, string]
  ) {
    const [key, value]: [string, string] = currentKeyValuePair;
    const adjustedKey: string = key.replace(".", "_");
    adjustedContentfulData[adjustedKey] = value;
    return adjustedContentfulData;
  }, {});
}
