/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WidgetProductBundleUI } from '../models/WidgetProductBundleUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WidgetProductOrderControllerService {

    /**
     * Get products
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns WidgetProductBundleUI Successful request
     * @throws ApiError
     */
    public static getWidgetProducts(
        scopeId: string,
        tscid: string,
        lang: string = 'SV',
    ): CancelablePromise<Array<WidgetProductBundleUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/widget/scope/{scopeId}/organizations/{tscid}/products',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

}