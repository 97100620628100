/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmnRequestUI } from '../models/AddEmnRequestUI';
import type { AddToBasketRequestUI } from '../models/AddToBasketRequestUI';
import type { BasketUI } from '../models/BasketUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BasketControllerService {

    /**
     * Add EMN product to basket, specific endpoint for EMN based subscriptions only!
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static addEmnToBasket(
        scopeId: string,
        tscid: string,
        requestBody: AddEmnRequestUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/emn/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add EMN product to basket, specific endpoint for EMN based subscriptions only!
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static addEmnToBasket1(
        scopeId: string,
        tscid: string,
        requestBody: AddEmnRequestUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public/scope/{scopeId}/organizations/{tscid}/emn/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param lang
     * @returns BasketUI Successful request
     * @throws ApiError
     */
    public static getBasket(
        scopeId: string,
        tscid: string,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<BasketUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            query: {
                'lang': lang,
            },
        });
    }

    /**
     * Add product to basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static addToBasket(
        scopeId: string,
        tscid: string,
        requestBody: AddToBasketRequestUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete basket content
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns void
     * @throws ApiError
     */
    public static emptyBasket(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * Add product to basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static addToBasket1(
        scopeId: string,
        tscid: string,
        requestBody: AddToBasketRequestUI,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a product from basket
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @param basketLineId
     * @returns void
     * @throws ApiError
     */
    public static deleteFromBasket(
        scopeId: string,
        tscid: string,
        basketLineId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public/scope/{scopeId}/organizations/{tscid}/basket/{basketLineId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'basketLineId': basketLineId,
            },
        });
    }

}