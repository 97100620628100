/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ValidForDisconnectDTO } from '../models/ValidForDisconnectDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NumberValidationControllerService {

    /**
     * Validate MSISDN for disconnect
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param msisdn
     * @returns ValidForDisconnectDTO Successful request
     * @throws ApiError
     */
    public static validateMsisdnForExtension(
        scopeId: string,
        msisdn: string,
    ): CancelablePromise<ValidForDisconnectDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/validate-for-extension/{msisdn}',
            path: {
                'scopeId': scopeId,
                'msisdn': msisdn,
            },
        });
    }

}