import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import Scaffolding from "./components/Scaffolding";
import "@purpurds/purpur/styles";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "b2b-basket-wrapper": unknown;
      "b2b-broadband-product-listing": unknown;
      "b2b-layout": unknown;
    }
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  renderType: "createRoot",
  rootComponent: () => <Scaffolding />,
});

export const { bootstrap, mount, unmount } = lifecycles;
