/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmnCidr } from '../models/EmnCidr';
import type { EmnIpAddressInfoUI } from '../models/EmnIpAddressInfoUI';
import type { GetEmnSiteUI } from '../models/GetEmnSiteUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmnControllerService {

    /**
     * This service will validate the cidr in the body and if the cidr is valid return required IP information for the cidr
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param requestBody
     * @returns EmnIpAddressInfoUI Successful request
     * @throws ApiError
     */
    public static validateIpAddressesFromCidr(
        scopeId: string,
        requestBody: EmnCidr,
    ): CancelablePromise<EmnIpAddressInfoUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/organizations/{tscid}/emn/cidrvalidation',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get EMN sites from C2BC mobilitymanagement category
     * User must have PUI 'CREATE_ORDER' to access this resource
     * @param scopeId
     * @param tscid
     * @returns GetEmnSiteUI Successful request
     * @throws ApiError
     */
    public static getEmnSites(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<GetEmnSiteUI>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/organizations/{tscid}/emn-sites',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

}